import './styles.js'
import $ from 'jquery'
import lightbox from 'lightbox2'
import mask from './../vendor/mask'
// import AOS from 'aos'

import { customPreloader }  from './_customPreloader'
import { customSlider }     from './_customSlider'
import { customAnimation } 	from './_customAnimation'


(function($) {

  $( window ).on('load', () => {

    $('[type="tel"]').mask("+7 (999) 999-99-99")

		customAnimation()
    customPreloader()
    customSlider()

    lightbox.option({
      'resizeDuration': 300,
      'wrapAround': true,
      'albumLabel':  "Изображение %1 из %2"
    })

    $('.page-sidebar__category-trigger').click((event) => {
      event.preventDefault()

      let $this   = $( event.currentTarget )
      let target  = $this.attr('href')

      $( target ).stop(true, false).slideToggle(300)
      $( target ).closest('.page-sidebar__category').toggleClass('current')
    })

    $('[data-event="modal"]').click((event) => {
        event.preventDefault()

        let modalHandler = $( event.currentTarget )
        let modalHandlerTarget = modalHandler.attr('href')

        $(modalHandlerTarget).addClass('open')
      })
      
      $('[data-event="closeModal"]').click((event) => {
        event.preventDefault()

        let modalCloser = $( event.currentTarget )
        let modal       = modalCloser.closest('.modal')

        modal.removeClass('open')
      })

    $('.header-info__trigger').click(function(event) {
      event.preventDefault()

      $( this ).toggleClass('active')
      $( '.body, .mobile__menu' ).toggleClass('active')
    })

    let $value = 1;
    $('.page-cart__counter-trigger').click((event) => {
      let $this								= $( event.currentTarget )
      let $counterField 			= $this.closest('.page-cart__counter')
			let $couterPrice				= $this.closest('.page-cart__actions').find('.page-cart__price')
			let $counterPriceValue	=	parseInt($couterPrice[0].textContent)
      let $counter      			= $counterField.find('.page-cart__counter-value')

      if($this.hasClass('page-cart__counter-trigger--add')) {
        $value++
        $counter.text($value)
				$couterPrice.text($('.page-cart__trigger').attr('data-state-price') * $value + ' р.')
				$('.page-cart__trigger').attr('data-product-price', $counterPriceValue * $value + ' р.')
        $('.page-cart__trigger').attr('data-product-counter', $value)
      } else {
        if($value > 1) {
          $value--
          $counter.text($value)
					$couterPrice.text($('.page-cart__trigger').attr('data-state-price') * $value + ' р.')
					$('.page-cart__trigger').attr('data-product-price', $counterPriceValue * $value + ' р.')
          $('.page-cart__trigger').attr('data-product-counter', $value)
        }
      }
    })

    $('.page-cart__trigger').click((event) => {
      let $this = $( event.currentTarget )

      let name    = $this.attr('data-product-title')
      let price   = $this.attr('data-product-price')
      let counter = $this.attr('data-product-counter')

      $('[name="product_title"]').attr('value', name)
      $('[name="product_price"]').attr('value', price)
      $('[name="product_quantity"]').attr('value', counter)
    })
  })

  $( window ).on('resize', () => {
    if($(window).width() > 1200) {
      $('.body, .header-info__trigger, .mobile__menu').removeClass('active')
    }
  })
})(jQuery)

