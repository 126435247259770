import $ from 'jquery'
import slick from 'slick-carousel'

export const customSlider = () => {
  $('[data-slider="banner"]').slick({
    rows: 0,
    arrows: false,
    dots: true,
		autoplay: true
  })
  $('[data-slider="cart-slider"]').slick({
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    rows: 0,
    asNavFor: '[data-slider="cart-nav"]'
  });
  $('[data-slider="cart-nav"]').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '[data-slider="cart-slider"]',
    arrows: false,
    rows: 0,
    dots: false,
    focusOnSelect: true
  });
  
  $('[data-slider="recomends"]').slick({
    slidesToShow: 4,
    arrows: true,
    dots: false,
    rows: 0,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })
}